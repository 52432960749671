<template>
    <KCourse loader-class="MBcont" course-id="1" title="Анатомия головы" :items="items">
        
            <div class="MBtextcont">
                <p class="nomargin">
                    Закрепляем все знания на пластике. Кому-то из вас будет трудно
                    переключиться с пластилина на пластик и привыкать к новому материалу, а
                    кто-то вздохнет с облегчением. Сегодня мы:
                </p>
                <ol>
                    <li>Лепим лицо и делаем обрубовку</li>
                    <li>Проверяем голову на симметрию и учимся видеть свои ошибки!</li>
                </ol>
            </div> 
            
            <VideoView video-id="d78d3fecfa05416dad2402334b25c972"/>

            <div class="MBtextcont">
                <p>
                    Как всегда, этот материал потребует от вас много внимания и труда, но
                    все в этой жизни вознаграждается! Главный приз этого марафона - это ваш
                    опыт! Как бы пафосно это не звучало ))) Это то, что останется с вами и,
                    надеюсь, изменит ваше творчество в лучшую сторону. Мои куклы точно
                    изменятся уж не знаю, хорошо это или плохо…
                </p>
            </div>
        
    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
        },
        data() {
        return {
            items: [
                { title: "Лепим череп", url:"/mycourses/head/1"},
                { title: "Делаем обрубовку", url:"/mycourses/head/2"},
                { title: "Лепим лицо", url:"/mycourses/head/3"},
                { title: "Основа из La Doll", url:"/mycourses/head/4"},
                { title: "Создаем объемы", url:"/mycourses/head/5"},
                { title: "Доработка деталей", url:"/mycourses/head/6"},
            ],
        }
        }
    }
</script>